import React from 'react'
import './WAButton.scss'

interface Props {
    number: string
}


export default function WAButton ({ number }: Props) {
    return <div className="WAButton__Wrapper">
        <a href={`https://wa.me/${number}?text=${encodeURIComponent('Hola, estoy en el formulario de postulación y tengo unas dudas.')}`} className="WAButton__Link" target="_new">
            <span className="WAButton__Text">¿Dudas? ¡Escribenos!</span>
            <img className="WAButton__Image" src={require('./WAIcon.png')} alt='Icono de WhatsApp' />
        </a>
    </div>
}