import React from 'react'

import './App.scss'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import HomepageLayout from './Views/Homepage'
import MainLayout from './Views/Main'
import CourseLayout from './Views/Course'
import ActivateAccount from './Views/ActivateAccount'
import CourseSelector from './Views/CourseSelector'
import ThanksYou from './Views/ThanksYou'

import { getMainDefinition } from '@apollo/client/utilities'
import WAButton from './Components/WAButton/WAButton'

// @ts-ignore
const domain = new URL(window.location)
const fullname = domain.hostname.split('.').reverse()
const finalDomain = fullname
  .reduce((acc: string[], part: string, ndx: number) => {
    if (ndx < 2) {
      acc.push(part)
    }
    return acc
  }, [])
  .reverse()
  .join('.')
console.log(finalDomain)

const isProd = process.env.NODE_ENV === 'production'

const uri = isProd
  ? `https://api-formularios.${finalDomain}/graphql`
  : 'http://localhost:8000/graphql'

const uploadLink = createUploadLink({ uri })

const client = new ApolloClient({
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    // @ts-ignore
    createUploadLink({ uri, fetch }),
    uploadLink
  ),
  cache: new InMemoryCache({
    addTypename: false
  })
})

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className='App'>
          {process.env.REACT_APP_WHATSAPP && (
            <WAButton number={process.env.REACT_APP_WHATSAPP} />
          )}
          <Switch>
            <Route
              path='/'
              exact={
                (process.env.REACT_APP_DISABLE_PLATFORM || 'false') === 'false'
              }
            >
              <HomepageLayout />
            </Route>
            {(process.env.REACT_APP_DISABLE_PLATFORM || 'false') ===
              'false' && (
              <>
                <Route path='/forms_selector/:token'>
                  <MainLayout />
                </Route>
                <Route path='/selector/:token'>
                  <CourseSelector />
                </Route>
                <Route path='/activate/:token'>
                  <ActivateAccount />
                </Route>
                <Route path='/thanks_you/:token'>
                  <ThanksYou />
                </Route>
                <Route path='/form/:id/:token'>
                  <CourseLayout />
                </Route>
              </>
            )}
          </Switch>
        </div>
      </Router>
    </ApolloProvider>
  )
}

export default App
