import React, { useState, useEffect } from 'react'
import {
  Button,
  Icon,
  Segment,
  Form,
  Input,
  Transition,
  Dimmer,
  Loader,
  Modal,
  Header
} from 'semantic-ui-react'

import LogoPiT from '../Assets/LogoPiT.png'
import PiTMorado from '../Assets/PiTMorado.svg'

import { useMutation } from '@apollo/client'
import { USER_LOGIN } from './connection'
import { useHistory } from 'react-router-dom'

const HomepageLayout = () => {
  const [mail, setMail] = useState('')
  // const [code, setCode] = useState(0)

  const [modal, setModal] = useState(false)
  const [modalProps, setModalProps] = useState<any>({
    title: '',
    desc: 'El usuario indicado no es válido.'
  })

  const [stateApp, setStateApp] = useState('INIT')

  const history = useHistory()

  const [userLogin, { data: dataLogin, error: errorLogin, loading }] =
    useMutation(USER_LOGIN, {
      onError: (error) => {
        setModalProps({
          ...modalProps,
          desc: error.toString()
        })
        setModal(true)
      }
    })

  useEffect(() => {
    console.log({ errorLogin, dataLogin })
    if (errorLogin) {
      console.log({ errorLogin })
    }

    if (dataLogin) {
      const {
        UserLogin: { state, text }
      } = dataLogin

      // Code was sent to user.
      setStateApp(state)
      console.log({ text, state })
      if (state === 'OK_LOGIN') {
        history.push(`/forms_selector/${dataLogin.UserLogin.token}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLogin, errorLogin])

  return (
    <>
      {loading && (
        <Dimmer active>
          <Loader indeterminate>Cargando datos...</Loader>
        </Dimmer>
      )}

      {modal && (
        <Modal
          size='mini'
          onClose={() => setModal(false)}
          onOpen={() => setModal(true)}
          open={modal}
        >
          <Header as='h2'>
            <Icon name='warning sign' />
            <Header.Content>
              Error
              <Header.Subheader>Oops, ha ocurrido un problema</Header.Subheader>
            </Header.Content>
          </Header>
          <Modal.Content>
            <p>{modalProps.desc}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' inverted onClick={() => setModal(false)}>
              <Icon name='checkmark' /> Intentar nuevamente
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      <div className='PiT__LoginScreen'>
        <div className='PiT__LoginScreen__Left'>
          <div className='PiT__LoginScreen__Left__Wrapper'>
            <div className='PiT__LoginScreen__Left__Logo'>
              <img
                src={LogoPiT}
                className='PiT__LoginScreen__Left__Logo'
                alt='PiT'
              />
            </div>
            <h2>¡Hola! Esta es la plataforma de postulación de PiT.</h2>
            {(process.env.REACT_APP_DISABLE_PLATFORM || 'false') === 'true' ? (
              <>
                <div className='PiT__LoginScreen__Left__LoginBox' style={{ marginLeft: 0, paddingLeft: 0 }}>
                  <p>
                    El proceso de postulación ha concluido.
                  </p>
                  <p>
                    Actualmente nuestro equipo de selección se encuentra revisando
                    las postulaciones recibidas y contactando a los
                    preseleccionados.
                  </p>
                  <p>Si postulaste, quédate atento/a a tu correo.</p>
                </div>
              </>
            ) : (
              <>
                <p>Para comenzar, ingresa tu correo electrónico:</p>
                <div className='PiT__LoginScreen__Left__LoginBox'>
                  <Segment>
                    <Transition.Group animation='fade left' duration={500}>
                      {stateApp === 'INIT' && (
                        <Form
                          inverted
                          className='FormLogin'
                          onSubmit={() => {
                            userLogin({
                              variables: {
                                UserLoginInput: {
                                  mail
                                }
                              }
                            })
                          }}
                        >
                          <Form.Field>
                            <Input
                              icon='address card outline'
                              iconPosition='left'
                              onChange={(e, data) => {
                                setMail(data.value)
                              }}
                              placeholder='ejemplo@mail.com'
                              action={{
                                color: 'teal',
                                type: 'submit',
                                size: 'big',
                                // labelPosition: 'right',
                                content: 'Entrar'
                              }}
                              size='big'
                            />
                          </Form.Field>
                          {/* <Button type='submit' size='big' color='teal'>
                      Entrar
                    </Button> */}
                        </Form>
                      )}
                    </Transition.Group>
                  </Segment>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='PiT__LoginScreen__Right'>
          <div className='PiT__LoginScreen__Right__Content'>
            <img src={PiTMorado} alt='Logo PiT' />
          </div>
        </div>
      </div>
    </>
  )
}
export default HomepageLayout
