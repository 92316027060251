import { gql } from '@apollo/client'

export const USER_LOGIN = gql`
    mutation UserLogin ($UserLoginInput: UserLoginInput!) {
        UserLogin (input: $UserLoginInput) {
            success
            state
            text
            token
            GroupInfo{
                id
                userGroupId
                internalName
                name
            }
        }
    } 
`

export const ACTIVATE_ACCOUNT = gql`
mutation UserActivate($UserActivateInput: UserActivateInput!) {
  UserActivate(input: $UserActivateInput) {
    success
  }
}
`

export const RESOURCE_LIST = gql`
query resourceList ($ResourceListInput: ResourceListInput!) {
  resourceList (input: $ResourceListInput) {
    resources{
      id
      typeId
      name
      description
      parentId
      id
      image
      json
      createdAt
      ResourceComplete{
        id
        answer
        pending
      }
    }
  }
}
`

export const RESOURCE_COMPLETE = gql`
mutation resourceComplete ($ResourceCompleteInput: ResourceCompleteInput!) {
  resourceComplete (input: $ResourceCompleteInput) {
    success
  }
}
`