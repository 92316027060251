import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useHistory } from 'react-router-dom'

import { ACTIVATE_ACCOUNT } from './connection'
import { useMutation, useQuery } from '@apollo/client'
import { Modal, Header, Icon, Button } from 'semantic-ui-react'

export default function ActivateAccount() {
  const history = useHistory()
  const { token } = useParams<any>()
  const [modal, setModal] = useState(false)
  const [activate, { data, loading }] = useMutation(ACTIVATE_ACCOUNT, {
    onError(error) {
      console.log(error)
      alert('Error al activar tu cuenta. Por favor, intenta de nuevo.')
    },
  })

  useEffect(() => {
    if (token) {
      activate({
        variables: {
          UserActivateInput: {
            token
          }
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.UserActivate?.success) {
      // Show Modal
      setModal(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])



  return <>Cargando tu información...
  <Modal
      size='mini'
      onClose={() => {
        setModal(false)
        history.push('/')
      }}
      onOpen={() => setModal(true)}
      open={modal}
    >
      <Header as='h2'>
      <Icon name='check circle outline' />
      <Header.Content>
        Activación de cuenta
        <Header.Subheader>Cuenta activada exitosamente</Header.Subheader>
      </Header.Content>
    </Header>
      <Modal.Content>
        <p>
          Tu cuenta ha sido activada. Por favor, ingresa con tu correo electrónico en la próxima pantalla.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button positive inverted onClick={() => {
          setModal(false)
          history.push('/')
        }}>
          <Icon name='checkmark' /> Continuar
        </Button>
      </Modal.Actions>
    </Modal>
    </>
}
