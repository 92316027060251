import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

export default function MainLayout() {
  const { token } = useParams<any>()
  const history = useHistory()

  useEffect(() => {
    history.push('/selector/' + token)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <>
  </>
}