import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useHistory } from 'react-router-dom'

import { RESOURCE_LIST } from './connection'
import { useQuery } from '@apollo/client'

export default function CourseSelector() {
  const [first, setFirst] = useState<number>()
  // @ts-ignore
  const { token } = useParams()
  const { data } = useQuery(RESOURCE_LIST, {
    variables: {
      ResourceListInput: {
        clientId: 3
      }
    },
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    fetchPolicy: 'no-cache'
  })

  const history = useHistory()

  useEffect(() => {
    if (data) {
      // Get first without ResourceComplete or pending
      const first = data?.resourceList?.resources.find((resource: any) => (!resource.ResourceComplete || resource?.ResourceComplete?.pending))
      if (first) {
        setFirst(first.id)
      } else {
        setFirst(-1)
      }
      // setFirst(data?.resourceList?.resources[0]?.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (first) {
      if (first > 0) {
        history.push(`/form/${first}/${token}`)
      } else {
        history.push(`/thanks_you/${token}`)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, history])

  return <>Cargando tu información...</>;
}
