import React, { useEffect, useState } from 'react'

import { useRecordWebcam } from 'react-record-webcam'

import {
  Form,
  Grid,
  Button,
  TextArea,
  Input,
  Rating,
  Radio,
  // Transition,
  // Card,
  Icon,
  // Image,
  Label,
  Header,
  Modal,
  Segment,
  Checkbox
} from 'semantic-ui-react'

import { InputFile } from 'semantic-ui-react-input-file'

import PiTMorado from '../Assets/PiTMorado.svg'

import { useParams } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'

// @ts-ignore
import MainSelector from '@contingente/react-comunas-selector/lib/modules/MainSelector'

import { useHistory } from 'react-router-dom'
import { RESOURCE_COMPLETE, RESOURCE_LIST } from './connection'
import { useMutation, useQuery } from '@apollo/client'
import { animateScroll as scroll, scroller } from 'react-scroll'

// <RecordWebcam
const RecordWebcam = ({currentValue, extraClass, field, onChange, file, setFile}: any) => {
  // React timer hook
  const [timer, setTimer] = useState<any>(null)
  const [leftSeconds, setLeftSeconds] = useState(60)

  const recordWebcam = useRecordWebcam({
    frameRate: 60,
    recordingLength: 60,
    mimeType: 'video/mp4'
  });

  const saveFile = async () => {
    const blob = await recordWebcam.getRecording();
    const filename = `archivo.mp4`;
    if (blob) {
      const _file = new File([blob], filename);
      console.log(_file, 'size is', _file.size/(1024*1024), 'MB')
      setFile(_file)
      onChange(filename)
    }
  };

  useEffect(() => {
    if (recordWebcam.status === 'RECORDING') {
      setTimer(setInterval(() => {
        setLeftSeconds(_l => _l - 1)
      }, 1000))
    } else {
      clearInterval(timer)
      setTimer(null)
    }

    if (recordWebcam.status === 'PREVIEW') {
      saveFile()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordWebcam.status])

  useEffect(() => {
    if (leftSeconds === 0) {
      recordWebcam.stop()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftSeconds])


  return <>
    <div>
      <Segment className="RecordWebcam__Segment" inverted>
        {timer && <Label size='huge' className="RecordWebcam__Timer" color="red">{leftSeconds}</Label>}
        {['INIT', 'CLOSED', 'OPEN'].includes(recordWebcam.status) && <Segment inverted className={`RecordWebcam__Options ${recordWebcam.status !== 'CLOSED' ? 'RecordWebcam__Options__Started' : ''}`}>
            <Header icon>
              <Icon name='camera' />
              ¡Prepárate para comenzar!
              <Header.Content><small>{field.text}</small></Header.Content>
            </Header>
            
            <Button.Group inverted>
                <Button onClick={recordWebcam.status === 'CLOSED' ? recordWebcam.open : recordWebcam.close}>{recordWebcam.status === 'CLOSED' ? 'Solicitar permisos' : 'Apagar cámara'}</Button>
              <Button.Or text={'o'} />
              <Button disabled={recordWebcam.status !== 'OPEN'} negative onClick={recordWebcam.start}>Iniciar grabación</Button>
            </Button.Group>
        </Segment>}
        {recordWebcam.status !== 'PREVIEW' && <>
          <video playsInline key={'RecordWebcam__Live'} ref={recordWebcam.webcamRef} autoPlay muted className='RecordWebcam__Video' />
          {recordWebcam.status === 'RECORDING' && <Button className='RecordWebcam__Stop' disabled={recordWebcam.status !== 'RECORDING'} negative onClick={() => {
            recordWebcam.stop()
          }}>Detener grabación</Button>}
        </>}
        <video playsInline ref={recordWebcam.previewRef} autoPlay muted={recordWebcam.status !== 'PREVIEW'} className={`RecordWebcam__Video__Preview_st${recordWebcam.status}`} controls />
        {recordWebcam.status === 'PREVIEW' && <Button className='RecordWebcam__Stop' onClick={() => {
          recordWebcam.retake()
          setLeftSeconds(60)
        }}>Volver a grabar</Button>}
      </Segment>

      {/* <p>Camera status: {recordWebcam.status}</p>
      <p>Left seconds: {leftSeconds}</p>
      <button onClick={recordWebcam.open}>Open camera</button>
      <button onClick={recordWebcam.start}>Start recording</button>
      <button onClick={recordWebcam.stop}>Stop recording</button>
      <button onClick={recordWebcam.retake}>Retake recording</button>
      <button onClick={recordWebcam.download}>Download recording</button>
      <button onClick={saveFile}>Save file to server</button> */}
      {/* <video ref={recordWebcam.previewRef} autoPlay muted loop /> */}
    </div>
  </>

}

const Matrix = ({ field, values, setValues, customIndex, isWord }: any) => {
  const [rows, setRows] = useState<any[]>([])
  const [columns, setColumns] = useState<any[]>([])
  const [rowHeight, setRowheight] = useState<number>(35)
  const [height, setHeight] = useState<number>(300)

  const rawData = JSON.parse(field.label)
  
  let bdValues = {
    rows: undefined
  }
  try {
    bdValues = JSON.parse(values[customIndex])
  } catch (err) {
    //
  }

  useEffect(() => {            
    setColumns(rawData.columns.map((col: any, index: number) => {
      return {
        ...col,
        editable: index > 0,
        ...(index > 0 ? {
          editor: null,
          frozen: true,
          headerRender: true,
          draggable: false
        } : {}),
        ...(bdValues.rows !== undefined ? {
          editable: false,
          editor: null,
          frozen: true,
          draggable: false
        } : {})
      }
    }))
  
    setRows(bdValues.rows !== undefined ? bdValues.rows : rawData.rows.map((row: any, ndxNdx: number) => {
    const rawRow = Object.entries(row).sort((a, b) => {
        const [, vala] = a
        const [, valb] = b
        return (typeof valb === 'string' ? 1 : 0) - (typeof vala === 'string' ? 1 : 0)
      })
      
      const colsFilled: string[] = []

      const parsed = rawRow.reduce((acc, cell: any) => {
        let formatCell: Object = {}
        const [index, val] = cell
        const filled = typeof val === 'string'
        const propertyId: string = filled ? index : Object.keys(val)[0]
  
        if (!colsFilled.includes(propertyId)) {
          // @ts-ignore
          formatCell[propertyId] = filled ? val : ''
          // @ts-ignore
          formatCell['allowEdit'] = !filled
          colsFilled.push(propertyId)
          // console.log({ formatCell })
          // @ts-ignore
          acc.push(formatCell)
        }

        return acc
      }, [])
      

      return parsed.reduce((acc, pa: any) => {
        const { allowEdit, ...rest } = pa
        const [[property, value]] = Object.entries(rest)

        // @ts-ignore
        acc[property] = value
  
        return acc
      }, {
        allowEdit: true
      })
    }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (rows.length > 0) {
      const largeText = rows.reduce((acc, r) => {
        return acc < r.col0.length ? r.col0.length : acc
      }, 0)
      const computed = Number((Math.ceil(largeText / 35) * 24) + 12)
      const finalValue = computed < 35 ? 35 : computed
      /* console.log({
        largeText,
        computed,
        finalValue
      })  */
      setRowheight(finalValue)
      setHeight((finalValue*rows.length) + (rows.length * 1) + 40)
    }
  }, [rows])

  if (isWord) {
    // @ts-ignore
    const flatRows = bdValues.rows !== undefined ? bdValues.rows.reduce((acc, row) => {
      delete row.allowEdit
      acc.push(Object.values(row))
      return acc
    }, [rawData.columns.reduce((acc: string[], col: any)=> {
      acc.push(col.name)
      return acc
    }, [])]) : []

    return <table style={{ width: '100%' }}>
      {flatRows.map((row: string[], indexRow: number) => <tr>
        {row.map((cell: string, indexCell: number) => {
          let Tsx = (children: any) => <td>{children}</td>
          if (indexCell === 0 || indexRow === 0) {
            Tsx = (children: any) => <th>{children}</th>
          }
          return Tsx(cell)
        })}
      </tr>)}
    </table>
  }

  return <ReactDataGrid
      columns={columns}
      rowGetter={(i: any) => rows[i]}           
      enableCellSelect={true}
      headerRowHeight={32}
      rowHeight={rowHeight}
      // @ts-ignore
      onGridRowsUpdated={({ fromRow, toRow, updated }) => {
        const localRows = rows.slice()
        
        for (let i = fromRow; i <= toRow; i++) {
          // console.log({ i, a: rows[i], updated })
          localRows[i] = { ...rows[i], ...updated }                  
        }
        setRows(localRows)
        // onUpdateMatrix()

        /* const rowsOK = localRows.reduce((acc, localRow) => {
          if (acc) {
            for (const [property, val] of Object.entries(localRow)) {
              if (!['allowEdit', 'col0'].includes(property)) {
                acc = `${val}`.trim().length > 0
              }
            }
          }
          return acc
        }, true)

        let valid = null
        if (rowsOK) {
          valid = JSON.stringify({ rows: localRows })
        } */

        const update = values
        update[customIndex] = JSON.stringify({ rows: localRows })
        
        setValues([...update])
      }}   
      rowsCount={rows.length}
      onCellSelected={(({idx}: any) => {
        const firstColumn = idx === (columns.length - 1)
        // @ts-ignore
        document.getElementsByClassName('rdg-selected')[0].style.opacity = firstColumn ? 0 : 1
      })}
      minHeight={height} />
}

const CourseLayout = () => {
  // @ts-ignore
  let { id, token } = useParams()
  const [pto, setPto] = useState(0)
  const [complete, stateComplete] = useMutation(RESOURCE_COMPLETE, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    onError(error) {
      alert(`Error al guardar, ${error.message}.`)
    },
  })
  
  const { loading, data: rawData, refetch } = useQuery(RESOURCE_LIST, {
    variables: {
      ResourceListInput: {
        clientId: 5,
        parentId: id >= 10 ? 51 : 0
      }
    },
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    fetchPolicy: 'no-cache'
  })
    
  id = Number(id)
  let lmsCheck: any

  useEffect(() => {
    if (pto !== 0) {
      console.log('Puntaje ha cambiado ', pto)
    }
  }, [pto])

  useEffect(() => {
    if (id && Number(id) && id > 40) {
      scroller.scrollTo('scrollContainerContentType0', {
        duration: 1500,
        delay: 100,
        smooth: true
      })
    }
    scroll.scrollToTop()
    setCanAnswer(false)
    refetch()
    

    return function cleanup () {
      clearInterval(lmsCheck)
    }
  // eslint-disable-next-line
  }, [,id])

  useEffect(() => {
    const { loading, data } = stateComplete
    if (!loading && data?.resourceComplete) {
      if (data?.resourceComplete.success) {
        history.push(currentCard.parentId === 0 ? `/selector/${token}/courses` : `/course/${currentCard.parentId}/${token}`) 
      } else {
        // err
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateComplete.data])

  const history = useHistory()


  // const initialValue = new Array(1).fill('')
  const [values, setValues] = useState<any>(null)
  const [grouping, setGrouping] = useState<any[]>([])
  const [, setVisible] = useState([true])
  const [currentCard, setCurrentCard] = useState<any>()
  const [canAnswer, setCanAnswer] = useState<boolean>(false)
  const [missed, setMissed] = useState<string[]>([])
  const [modal, setModal] = useState<boolean>(false)
  const [file, setFile] = useState<any>(null)

  useEffect(() => {
    if (rawData?.resourceList?.resources) {
      
      // Get first without ResourceComplete or pending
      const first = rawData?.resourceList?.resources.find((resource: any) => (!resource.ResourceComplete || resource?.ResourceComplete?.pending))
      if (`${first?.id}` !== `${id}`) {
        history.push(`/form/${first?.id}/${token}`)
      }
    
      const current = rawData?.resourceList?.resources.find((c: any) => c.id === id)
      setCurrentCard(current)
      const localJSON = JSON.parse(current.json)

      if (current.typeId === 2) {
        // Consulta por preguntas respondidas
        if (current?.ResourceComplete) {
          setValues(JSON.parse(current.ResourceComplete.answer))
          if (current.ResourceComplete.pending) {
            setCanAnswer(true)
          }
        } else {
          setCanAnswer(true)
          setValues(new Array(localJSON.length).fill('')) 
        }
      }
      if (current.typeId === 0) {
        setGrouping(rawData?.resourceList?.resources.filter((r: any) => r.id !== id).map((r:any) => {
          return {
            ...r,
            status: r.ResourceComplete ? 'Completado' : 'Disponible',
            url: `/course/${r.id}/`
          }
        }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, loading, id])

  useEffect(() => {
    if (grouping.length > 0) {
      setVisible(new Array((grouping.length + 1)).fill(true))
    }
  }, [grouping])

  if (loading) return <>Cargando tu información...</>;

  // const resourcesCompletes = grouping.reduce((acc, g) => 
  //   acc + (g?.ResourceComplete !== null ? 1 : 0)
  // , 0)
  
  const data: any[] = rawData.resourceList.resources

  const currentCourse = data.find(course => course.id === id)
  let { json, typeId } = currentCourse
  json = JSON.parse(json)

  return <>
  {modal && <Modal
      size='mini'
      onClose={() => {
        setModal(false)
        setMissed([])
      }}
      onOpen={() => setModal(true)}
      open={modal}
    >
      <Header as='h2'>
      <Icon name='warning sign' />
      <Header.Content>
        Error
        <Header.Subheader>Oops, ha ocurrido un problema</Header.Subheader>
      </Header.Content>
    </Header>
      <Modal.Content>
        <p>
          Todos los campos son obligatorios, por favor completa los siguientes campos:
          <ul>
            {missed.map((m, i) => <li key={i}>{m}</li>)}
          </ul>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' inverted onClick={() => setModal(false)}>
          <Icon name='checkmark' /> Aceptar
        </Button>
      </Modal.Actions>
    </Modal>}
    <div className={`MainContent ContentType${typeId}`}>
      <img src={PiTMorado} alt='Logo PiT' className='MainContent__Logo' />
      {typeId === 2 && 
        <Form>
          <Grid>
          {values !== null && <MainContent {...{
            values, setValues, json, canAnswer, currentCard, token, stateComplete, history, complete, id, currentCourse, modal, setModal, missed, setMissed, file, setFile
          }} />}
          </Grid>
        </Form>}
    </div>

    
  </>
}

const MainContent = ({ isWord = false, values, setValues, json, canAnswer, currentCard, token, stateComplete, history, complete, id, currentCourse, setModal, setMissed, file, setFile }: any) => {
  const [downloadWord, setDownload] = useState<boolean>(false)

  const sent = (pending = false) => {
    const ResourceCompleteInput = {
      resourceId: Number(id),
      answer: JSON.stringify(values),
      pending
    }

    if (file) {
      // @ts-ignore
      ResourceCompleteInput.bufferFile = file
    }
    complete({
      variables: {
        ResourceCompleteInput
      }
    })
  }
  return <Grid.Row className='MerriweatherBox'>
    <Grid.Column>
      {json.map((field: any, index: number) => {
        return <ElementParser isWord={isWord} canAnswer={canAnswer} field={field} index={index} {...{values, setValues}} file={file} setFile={setFile} />
      })}

      <Grid.Row className='row-sendButton'>
        <Button size='large' loading={stateComplete.loading} disabled={stateComplete.loading || id === 3} onClick={() => {
          sent(true)
        }}>Guardar y continuar después</Button>
        
        <Button size='large' loading={stateComplete.loading} disabled={stateComplete.loading} inverted={false} color='teal' onClick={() => {
            const fields = json as any[]

            const excludeIndexes = fields.reduce((acc, f, ndx) => {
              const types = [
                'html'
              ]
              if (types.includes(f.type)) {
                return [...acc, ndx]
              }
              return acc
            }, [])
                
            const filteredValues = values.filter((v: any, ndx: number) => !excludeIndexes.includes(ndx))
            const filteredFields = fields.filter((f: any, ndx: number) => !excludeIndexes.includes(ndx))
            
            const getTextOrLabel = (_field: any) => {
              if (['video', 'upload'].includes(_field.type)) {
                  return _field.text
              }
              if (_field.label === 'RAW_CITY_SELECT') {
                return 'Región / Ciudad'
              }
              return _field.label
            }

            const _missed = []
            let _ndx = 0
            for (const _value of filteredValues) {
              const _field = filteredFields[_ndx]
              if (_value || `${_value}` ) {
                if ( `${_value}`.trim() === '') {
                  _missed.push(getTextOrLabel(_field))
                }
              } else {
                _missed.push(getTextOrLabel(_field))
              }
              _ndx++
            }

            if (_missed.length > 0) {
              setMissed(_missed)
              setModal(true)
            } else {
                sent()
            }
          }}>{id === 3 ? 'Completar postulación' : 'Continuar con el formulario'}</Button>
      </Grid.Row>
    </Grid.Column>
  </Grid.Row>
}


const ElementParser = ({ field, index, values, setValues, isChild = false, extraClass, canAnswer, isWord, file, setFile }: any) => {
  const basePositive = field.type === 'conditional' ? (values && index && values[index] && values[index].length > 1 && values[index][0] === 0 ? true : (typeof values[index] === 'object' ? false : null)) : null
  const [positive, setPositive] = useState<null|boolean>(basePositive)

  const elements = []

  /**
   * Campo de HTML plano.
   * @saves - "" - Empty.
   * @render - HTML.
   * @word - Texto plano.
   */
  if (field.type === 'html') {
    elements.push(<div dangerouslySetInnerHTML={{ __html: field.text }} />)
  }


  /**
   * Campo de Texto Calendario
   * @saves "value" - String con valor.
   * @render - Input type="text".
   * @word - Texto plano con respuesta seleccionada. Si no fue respondido, muestra en cursiva "sin respuesta".
   */
  if (field.type === 'calendar') {
    if (isChild) {
      field.label = `${field.text}`
    }
    const update = values

    if (isWord) {
      elements.push(<Form.Field type='textarea' className={extraClass}>
        <strong><label>{field.label}</label></strong><br />
        <p><i>{update[index]}</i></p>
      </Form.Field>)
    } else {
      elements.push(<Form.Field type='textarea' className={extraClass}>
        <label>{field.label}</label>
        <Input type='date' disabled={!canAnswer} fluid placeholder={isChild ? field.placeholder : field.text} onChange={(event, { value }) => {
          update[index] = value
          setValues([...update])
        }} value={update[index]} />
      </Form.Field>)
    }
  }


  /**
   * Campo de Texto Uni-línea
   * @saves "value" - String con valor.
   * @render - Input type="text".
   * @word - Texto plano con respuesta seleccionada. Si no fue respondido, muestra en cursiva "sin respuesta".
   */
  if (field.label === 'RAW_CITY_SELECT' && field.type === 'line') {
    if (isChild) {
      field.label = `${field.text}`
    }
    const update = values

    const currValue = typeof update[index] !== 'object' ? [] : update[index]
    
    const [region, comuna] = currValue

    elements.push(<MainSelector
      defaultValueReg={region}
      onchangeReg={(selected: string) => {
        const [_, comuna] = currValue
        update[index] = [selected, comuna]

        setValues([...update])
      }}
      defaultValueCom={comuna}
      onchangeCom={(selected: string) => {
        const [region, _] = currValue
        update[index] = [region, selected]
        setValues([...update])
      }}
    />)
  }

  /**
   * Campo de Texto Uni-línea
   * @saves "value" - String con valor.
   * @render - Input type="text".
   * @word - Texto plano con respuesta seleccionada. Si no fue respondido, muestra en cursiva "sin respuesta".
   */
  if (field.type === 'line' && field.label !== 'RAW_CITY_SELECT') {
    if (isChild) {
      field.label = `${field.text}`
    }
    const update = values

    if (isWord) {
      elements.push(<Form.Field type='textarea' className={extraClass}>
        <strong><label>{field.label}</label></strong><br />
        <p><i>{update[index]}</i></p>
      </Form.Field>)
    } else {
      elements.push(<Form.Field type='textarea' className={extraClass}>
        <label>{field.label}</label>
        <Input disabled={!canAnswer} maxLength={150} fluid placeholder={isChild ? field.placeholder : field.text} onChange={(event, { value }) => {
          update[index] = value
          setValues([...update])
        }} value={update[index]} />
      </Form.Field>)
    }
  }

  /**
   * Campo de Video
   * @saves "value" - String con valor.
   * @render - Input type="text".
   * @word - Texto plano con respuesta seleccionada. Si no fue respondido, muestra en cursiva "sin respuesta".
   */
  if (field.type === 'video') {
    if (isChild) {
      field.label = `${field.text}`
    }
    const update = values

    elements.push(<Form.Field className={extraClass}>
      <RecordWebcam currentValue={update[index]} file={file} setFile={setFile} extraClass={extraClass} field={field} onChange={(filename: string) => {
      if (filename === null) {
        update[index] = ''
      } else {
        if (filename) {
          update[index] = filename
        } else {
          update[index] = ''
        }
      }
      setValues([...update])
    }}  />
    </Form.Field>)
  
  }

  /**
   * Campo de Subida
   * @saves "value" - String con valor.
   * @render - Input type="text".
   * @word - Texto plano con respuesta seleccionada. Si no fue respondido, muestra en cursiva "sin respuesta".
   */
  if (field.type === 'upload') {
    if (isChild) {
      field.label = `${field.text}`
    }
    const update = values

    elements.push(<FieldInputFile currentValue={update[index]} file={file} setFile={setFile} extraClass={extraClass} field={field} onChange={(event: any) => {
      if (event === null) {
        update[index] = ''
      } else {
        if (event && event.target && event.target.files && event.target.files[0]) {
          update[index] = event.target.files[0].name
        } else {
          update[index] = ''
        }
      }
      setValues([...update])
    }} />)
  }

  /**
   * Campo de Texto Multi-línea
   * @saves "value" - String con valor.
   * @render - Textarea.
   * @word - Texto plano con respuesta seleccionada. Si no fue respondido, muestra en cursiva "sin respuesta".
   */
  if (field.type === 'multiline') {
    let update = values
    if (isChild) {
      field.label = `${field.text}`
      if (update === null) {
        update = []
        update[index] = ''
      }
    }

    if (isWord) {
      elements.push(<Form.Field type='textarea' className={extraClass}>
        <strong><label>{field.label}</label></strong><br />
        <p><i>{update[index]}</i></p>
      </Form.Field>)
    } else {
      elements.push(<Form.Field type='textarea' className={extraClass}>
        <label>{field.label}</label>
        <TextArea
          disabled={!canAnswer}
          placeholder={'Escribe aquí tu respuesta (máximo 1500 caracteres).'}
          // placeholder={isChild ? field.placeholder : field.text ?? 'Máximo 1500 caracteres.'}
          value={update[index]}
          maxLength={1500}
          onChange={(event, { value }) => {
            update[index] = value
            setValues([...update])
          }} />
      </Form.Field>)
    }
  }

  /**
   * Escala de Likert.
   * @saves "number" - Elección del 1 al máximo (defaultValue, 5)
   * @render - Div con estrellas.
   * @word - @todo - Texto plano que indica "1 de 5".
   */
  if (field.type === 'likert') {
    // console.log({ field })
    // const icons = field.ext1 === true
    elements.push(<Form.Field>
      <label>{field.label}</label>
      <Rating disabled={!canAnswer} size='huge' icon='star' maxRating={field.ext2} onRate={(e, { rating }) => {
        const update = values
        update[index] = rating
        setValues([...update])
      }} />
    </Form.Field>)
  }


  /**
   * Checkbox option.
   * @saves "value" - String con valor (índice).
   * @render - Radio group con opciones.
   * @word - Texto plano que indica valor del índice seleccionado, muestra en cursiva "sin respuesta".
   */
  if (field.type === 'checkbox') {
    const update = values
    const options = [field.label]
    // const options = JSON.parse(field.ext1)

    let currValue = (typeof update[index] !== 'object') ? [] : update[index]
    console.log('currValue', {currValue, field})

    elements.push(<>
    {/* <Form.Field>
      <label>{field.label}</label>
    </Form.Field> */}
    {options.filter((optin: string) => optin.trim().length > 0).map((optin: string, ndx: number) => <Form.Field key={`Checkbox${ndx}`}>
      <Checkbox
        label={optin}
        name={`Checkbox${field.id}-${ndx}`}
        disabled={!canAnswer}
        value={ndx}
        checked={currValue.includes(ndx)}
        onChange={(event, { value }) => {
          if (currValue.includes(value)) {
            currValue = currValue.filter((val: number) => val !== value)
          } else {
            currValue.push(value)
          }
          update[index] = currValue
          setValues([...update])
        }} 
      />
    </Form.Field>)}
    </>)
  
  }

  /**
   * Radio option.
   * @saves "value" - String con valor (índice).
   * @render - Radio group con opciones.
   * @word - Texto plano que indica valor del índice seleccionado, muestra en cursiva "sin respuesta".
   */
  if (field.type === 'radio') {
    const update = values
    const options = JSON.parse(field.ext1)

    if (isWord) {
      elements.push(<>
      <Form.Field>
        <label>{field.label}</label>
      </Form.Field>
      {update[index] !== '' ? <strong>{options[update[index]]}</strong> : <i>sin respuesta</i>}
      </>)
    } else {
      elements.push(<>
      <Form.Field>
        <label>{field.label}</label>
      </Form.Field>
      {options.filter((optin: string) => optin.trim().length > 0).map((optin: string, ndx: number) => <Form.Field key={`Option${ndx}`}>
        <Radio
          label={optin}
          name={`RadioGroup${field.id}`}
          disabled={!canAnswer}
          value={ndx}
          checked={update[index] === ndx}
          onChange={(event, { value }) => {
            update[index] = value
            setValues([...update])
          }} 
        />
      </Form.Field>)}
      </>)
    }
  }

  /**
   * Tabla Matrix
   * @todo
   */
  if (field.type === 'matrix') {
      elements.push(<Matrix field={field} setValues={setValues} values={values} customIndex={index} isWord={isWord} />)
  }

  /**
   * Condicional
   * @basedOn - @line | @multine | @html
   */
  if (field.type === 'conditional') {
    const options = ['Sí', 'No']
    const update = values
    

    if (isWord) {
      elements.push(<>
        <Form.Field>
          <label>{field.label}</label>
        </Form.Field>
        {update[index][0] !== '' ? <strong>{options[update[index][0]]}</strong> : <i>sin respuesta</i>}
        </>)
    } else {
      elements.push(<>
        <Form.Field>
          <label>{field.label}</label>
        </Form.Field>
      {options.map((optin: string, ndx: number) => <Form.Field key={`Option${ndx}`}>
        <Radio
          label={optin}
          disabled={!canAnswer}
          name={`ConditionalRadioGroup${field.id}`}
          value={ndx}
          checked={update[index] ? update[index][0] === ndx : false}
          onChange={(event, { value }) => {
            update[index] = [value, null]
            const isPositive = value === 0
            setValues([...update])
            setPositive(isPositive)
          }} 
        />
      </Form.Field>)}
      </>)
    }

      if (positive !== null){
        // positive = ext1
        // negative = ext2
        elements.push(<ElementParser
            isWord={isWord}
            canAnswer={canAnswer}
            field={positive ? field.ext1[0][0].data : field.ext2[0][0].data}
            index={index}
            // values={field.ext1}
            values={values[index][1]}
            extraClass={positive ? 'positive' : 'negative'}
            isChild={true}
          setValues={(extdata: any) => {
            const updated = Array.from(values)
            // console.log({ updated, positive, index })
            // @ts-ignore
            updated[index][1] = extdata
            
            setValues(updated)
          }} key={`ConditionalParser${positive ? 1 : 0}_${index}`} />)
        
      }
  }

  return <Grid.Row className={`row-${field.type}`}>
    {elements.map(a => a)}
  </Grid.Row>
}

const FieldInputFile = ({currentValue, extraClass, field, onChange, file, setFile}: any) => {
  if (currentValue && currentValue.length > 0 && currentValue.indexOf('http') === 0) {
    return <Form.Field className={extraClass}>
      <strong><Icon color='teal' name='check circle' /> Cargaste correctamente tu "{field.text}".<br />
      <small style={{
        fontWeight: 100,
         paddingLeft: 28
      }}>Puedes revisar el archivo haciendo clic <a href={currentValue} target="_new"> aquí</a> ó puedes <span onClick={() => {
        setFile(null)
        onChange(null)
      }}>reemplazarlo</span></small></strong>
    </Form.Field>
  }
  
  return <Form.Field className={extraClass}>
        <label>{field.text}</label>
        <InputFile
        button={{ 
          label: 
            <Label
              as='label'
              style={{ cursor: 'pointer', color: '#000' }}
              className='forceBlack'
              basic
              children={file ? file.name : (field.label ?? 'Seleccionar archivo')}
            />
          
         }}
        input={{
          id: 'input-control-id',
          className: extraClass,
          onChange: (event) => {
            setFile(event.target.files[0])
            onChange(event)
          }
        }}
      /></Form.Field>
}

export default CourseLayout