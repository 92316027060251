import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Form, Grid } from 'semantic-ui-react'

import PiTMorado from '../Assets/PiTMorado.svg'
export default function ThanksYou() {
  const history = useHistory()

  const [modal, setModal] = useState(false)

  return (
    <div className={`MainContent ContentType1 ThanksYou`}>
      <img src={PiTMorado} alt='Logo PiT' className='MainContent__Logo' />

      <Form>
        <Grid>
          <Grid.Row className='MerriweatherBox'>
            <Grid.Column>
              <div className="row row-html">
                <div>
                  <h1>Formulario completado</h1>
                  <p>Gracias, una vez terminado el proceso de postulaciones estaremos en contacto vía correo.</p>
                  </div>
                </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  )
}
